import {
  createApp
} from 'vue';
import App from './App.vue';
import {
  router
} from './router';
import {
  Dialog,
  Toast,
  Calendar,
  Loading
} from "vant";
const app = createApp(App);
app.use(router);
app.use(Dialog);
app.use(Toast);
app.use(Calendar);
app.use(Loading);
app.mount('#app');