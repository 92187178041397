import {
  createRouter,
  createWebHashHistory
} from 'vue-router';

const routes = [{
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'book',
    },
  },
  {
    name: 'book',
    path: '/book',
    component: () => import('./view/book'),
    meta: {
      title: '我要报餐',
    },
  },
  {
    name: 'diningRules',
    path: '/diningRules',
    component: () => import('./view/book/diningRules'),
    meta: {
      title: '用餐规则',
    },
  },
  {
    name: 'check',
    path: '/check',
    component: () => import('./view/check'),
    meta: {
      title: '用餐核销',
    },
  },
  {
    name: 'mealRecord',
    path: '/mealRecord',
    component: () => import('./view/check/mealRecord'),
    meta: {
      title: '核销记录',
    },
  },
  {
    name: 'admin',
    path: '/admin',
    component: () => import('./view/admin'),
    meta: {
      title: '管理中心',
    },
  },
  {
    name: 'rp',
    path: '/rp',
    component: () => import('./view/rp'),
    meta: {
      title: '报表',
    },
  },
  {
    name: 'no',
    path: '/no',
    component: () => import('./view/no'),
    meta: {
      title: '提醒',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};